<template>
  <div id="app">
    <!-- <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay> -->

    <!-- loading skeleton Start -->
    <!-- <v-col v-show="loading == true">
      <v-sheet
        height="565"
        class="pa-2 mb-n5"
        max-width="6000"
        :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-5'"
      >
        <div>
          <v-card-actions>
            <v-skeleton-loader
              width="400"
              type="heading"
            ></v-skeleton-loader>
            <v-spacer></v-spacer>

            <v-skeleton-loader
              type="card"
              width="80"
              height="35"
            ></v-skeleton-loader>
            <v-skeleton-loader
              type="card"
              width="80"
              height="35"
              class="ml-2"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>
        
        <div>
          <v-skeleton-loader
            tile
            height="50"
            type="image"
            class="mt-1"
          ></v-skeleton-loader>
        </div>

        <div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-skeleton-loader
              type="image"
              width="250"
              height="20"
              class="rounded-xl mx-n1 mt-1 mb-1"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>

        <div>
          <v-skeleton-loader
            tile
            height="200"
            type="image"
          ></v-skeleton-loader>
          <v-skeleton-loader
            tile
            height="132"
            type="image"
            class="mb-3"
          ></v-skeleton-loader>
        </div>

        <div>
          <v-card-actions>
            <v-skeleton-loader
              width="800"
              type="heading"
              class="mt-1 mx-n2"
            ></v-skeleton-loader>

            <v-spacer></v-spacer>

            <v-skeleton-loader
              type="image"
              width="170"
              height="20"
              class="rounded-xl mx-n1"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>
      </v-sheet>
    </v-col> -->
    <!-- loading skeleton End -->

    <!-- <v-skeleton-loader
    class="mx-2"
      max-width="5000"
      height="80"
      type="actions"
      v-show="loading == true"
    ></v-skeleton-loader>
    <v-skeleton-loader
      max-width="200"
      class="mt-n15"
      type="card-heading"
      v-show="loading == true"
    ></v-skeleton-loader>
    <v-skeleton-loader
      class="mx-2 my-n2"
      max-width="5000"
      height="80"
      type="image"
      v-show="loading == true"
    ></v-skeleton-loader>
    <v-skeleton-loader
      class="my-n5"
      max-width="5000"
      height="80"
      type="table-heading"
      v-show="loading == true"
    ></v-skeleton-loader>
    <v-skeleton-loader
      class="mx-2"
      max-width="5000"
      type="image,actions"
      v-show="loading == true"
    ></v-skeleton-loader> -->

    <v-col v-show="this.valid == false">
      <v-alert text type="error">
        Kamu tidak punya akses Ke halaman "KontrakKaryawan"
      </v-alert>
    </v-col>

    <!-- Alert Data Tersimpan -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      elevation="4"
      color="success"
      v-model="Alert"
      class="rounded-lg"
      icon="mdi-check-bold"
      transition="slide-x-transition"
    >
      <v-icon class="mr-1">check-circle</v-icon>
      Data Berhasil Disimpan
    </v-alert>

    <!-- Alert Data Berhasil Terubah -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      elevation="4"
      color="success"
      class="rounded-lg"
      icon="mdi-check-bold"
      v-model="AlertBerhasilTerubah"
      transition="slide-x-transition"
    >
      Data Berhasil Diubah
    </v-alert>

    <!-- Alert Data Sudah Terhapus -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      color="error"
      elevation="4"
      class="rounded-lg"
      icon="mdi-delete"
      v-model="AlertDataTerhapus"
      transition="slide-x-transition"
    >
      Data Berhasil Dihapus
    </v-alert>

    <!-- ---------------------------------------------------- -->

    <v-container fluid fill-height v-show="this.valid == true">
      <v-card tile outlined class="elevation-3 pa-2">
        <v-toolbar
          flat
          outlined
          height="55"
          color="dark"
          v-resize="onResize"
        >
          <v-toolbar-title>
            Data Kontrak Karyawan
          </v-toolbar-title>
          <v-divider inset vertical class="ml-2"></v-divider>
          <v-spacer></v-spacer>

          <!-- Menu Export Data -->
          <div class="text-center mx-2">
            <v-menu
              v-model="MenuExport"
              :close-on-content-click="false"
              :nudge-width="1"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <!-- Btn Export Mode Biasa -->
                <v-btn
                  @click="MenuExport = !MenuExport"
                  class="text-capitalize"
                  v-show="mobile == false"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  text
                  :disabled="loading == true"
                >
                  <v-icon class="ml-n2 mr-1">mdi-table-arrow-right</v-icon>
                  <span class="subtitle-1">Export Data</span>
                  <v-icon class="ml-1 mr-n3">{{ MenuExport ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </template>

              <v-card outlined height="100" class="pa-2" v-show="MenuExport">
                <!-- PDF -->
                <div class="mb-2">
                  <v-btn
                    text
                    block
                    outlined
                    @click.native="PdfExport"
                    class="text-capitalize subtitle-1"
                  >
                    Pdf
                    <v-icon right>mdi-adobe-acrobat</v-icon>
                  </v-btn>
                </div>

                <!-- EXCEL -->
                <div>
                  <v-btn
                    text
                    block
                    outlined                       
                    @click.native="excelExport"
                    class="text-capitalize subtitle-1"
                  >
                    Excel
                    <v-icon right>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </div>
              </v-card>
            </v-menu>

            <!-- Btn Export Dialog Mode Mobile -->
            <v-dialog
              v-model="DialogPilihExport"
              max-width="200"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="mobile == true"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  small
                  text
                  fab
                  :disabled="loading == true"
                >
                  <v-icon>mdi-table-arrow-right</v-icon>
                </v-btn>
              </template>
              <v-card class="rounded-lg">
                <v-toolbar dense>
                  <v-layout justify-center>
                    <v-toolbar-title>
                      Pilih Export File
                    </v-toolbar-title>
                  </v-layout>
                </v-toolbar>
                <v-card class="pa-2">
                  <v-btn text block outlined class="text-capitalize subtitle-1 mb-2" @click.native="PdfExport">
                    Pdf
                    <v-icon right>mdi-adobe-acrobat</v-icon>
                  </v-btn>

                  <v-btn text block outlined class="text-capitalize subtitle-1" @click.native="excelExport">
                    Excel
                    <v-icon right>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </v-card>
              </v-card>
            </v-dialog>
          </div>

          <!-- Dialog Tambah Kontrak -->
          <v-dialog
            v-model="DialogTambahKontrak"
            max-width="400px"
            scrollable
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <!-- Btn Tambah Mode Desktop -->
              <v-btn
                class="text-capitalize mr-n2"
                v-show="mobile == false && ValidCreate == true"
                v-bind="attrs"
                v-on="on"
                outlined
                text
                :disabled="loading == true"
              >
                <v-icon class="ml-n2 mr-1">mdi-plus-thick</v-icon>
                <span class="mr-n1 subtitle-1">Tambah</span>
              </v-btn>

              <!-- Btn Tambah Mode Mobile -->
              <v-btn 
                class="mr-n2"
                v-show="mobile == true && ValidCreate == true"
                v-bind="attrs"
                v-on="on"
                outlined
                small
                text
                fab
                :disabled="loading == true"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </template>
            <v-card class="rounded-lg">
              <v-toolbar flat height="64">
                <v-toolbar-title class="title">
                  <span class="text-h5 mx-1">{{ formTitleKontrak }}</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  @click="KeluarDialogKontrak"
                >
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-divider></v-divider>
              
              <v-card-text>
                <div class="mr-n3 ml-n3 mb-n2 mt-3">
                  <v-sheet outlined class="rounded-lg pa-5">
                    <v-row>
                      <!-- Pilih Karyawan -->
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-text-field
                          dense
                          readonly
                          label="Nama Karyawan"
                          color="dark"
                          v-model="editedItem.Nama"
                        >
                          <!-- :rules="[value => !!value || 'Wajib Diisi.']" -->
                          <template v-slot:append-outer>
                            <v-dialog
                              v-model="DialogPilihKaryawan"
                              max-width="1000px"
                              scrollable
                              persistent
                            >
                              <template v-slot:activator="{on, attrs}">
                                <v-btn
                                  dark
                                  x-small
                                  depressed
                                  height="25"
                                  v-bind="attrs"
                                  v-on="on"
                                  color="red darken-4"
                                  v-show="formTitleKontrak == 'Tambah Kontrak Baru'"
                                >
                                  <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                              </template>
                              <v-card outlined class="rounded-lg">
                                <v-toolbar flat height="64">
                                  <v-toolbar-title class="title">
                                    <span class="text-h5">{{ formTitlePilihKaryawan}}</span>
                                  </v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    fab
                                    text
                                    small
                                    @click="KeluarDialogPilihKaryawan"
                                  >
                                    <v-icon>mdi-window-close</v-icon>
                                  </v-btn>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-card-text>
                                  <div class="mb-n2 mr-n3 ml-n3">                                           
                                    <v-card tile outlined class="mt-3 elevation-3">
                                      <div class="pa-2">
                                        <ejs-grid
                                          ref='grikaryawan'
                                          height="208"
                                          gridLines='Both'
                                          id="karyawanview"
                                          :dataSource="DataKaryawan"
                                          :allowPaging="true"
                                          :allowSorting='true'
                                          :allowResizing='true'
                                          :toolbar='toolbarOptions'
                                          :recordDoubleClick="rowDblClickPilihKaryawan"
                                          :pageSettings='pageSettings'
                                          :rowSelected="rowSelectedPilihKaryawan" 
                                        >
                                          <e-columns>
                                            <!-- <e-column headerText="Aksi" textAlign='Center' width="120" :commands="commands" :lockColumn='true' :customAttributes="customAttributes">
                                              <div class="btn-group">
                                                <v-btn type="button" class="btn btn-default" (click)="prediemRowEdit($event)">
                                                  <i class="fa fa-pencil"></i>
                                                </v-btn>
                                                <v-btn type="button" class="btn btn-default" (click)="prediemRowDelete($event)">
                                                  <i class="fa fa-trash"></i>
                                                </v-btn>
                                              </div>
                                            </e-column> -->
                                            <e-column field='KodeKaryawan' headerText='Kode' textAlign='Left' width=120></e-column>
                                            <e-column field='Nama' headerText='Nama' textAlign='Left' width=300></e-column>
                                            <e-column field='jabatan.NamaJabatan' headerText='Jabatan' textAlign='Left' width=150></e-column>
                                            <e-column field='jabatan.Departemen' headerText='Departemen' textAlign='Left' width=150></e-column>
                                            <e-column field='jabatan.Divisi' headerText='Divisi' textAlign='Left' width=150></e-column>
                                          </e-columns>
                                        </ejs-grid>
                                      </div>
                                    </v-card>                                      
                                  </div>     
                                </v-card-text>                                   
                                <v-divider></v-divider>
                                <v-card-actions>
                                  <span class="d-inline-block text-truncate">
                                    Status : <span>{{StatusFormPilihKaryawan}}</span>
                                  </span>
                                <v-spacer></v-spacer>
                                  <v-btn
                                    dark
                                    color="red darken-4 mx-n2"
                                    class="text-capitalize"
                                    @click="PilihNamaKaryawan"
                                  >
                                    <v-icon class="mr-1">mdi-check-circle</v-icon>
                                    Pilih
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </template>
                        </v-text-field>
                      </v-col>

                      <!-- No Kontrak -->
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-text-field
                          dense
                          label="No Kontrak"
                          color="dark"
                          v-model="editedItem.NoKontrak"
                        ></v-text-field>
                      </v-col>

                      <!-- TanggalMulai -->
                        <v-col cols="12" sm="12" md="12" lg="12">
                          <v-dialog
                            v-model="MenuTglMulai"
                            :return-value.sync="date"
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.TanggalMulai"
                                label="Tgl Mulai"
                                prepend-icon="mdi-calendar"
                                color="dark"
                                readonly
                                dense
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.TanggalMulai"
                              @input="MenuTglMulai = false"
                              year-icon="mdi-calendar-blank"
                              :min="minDate"
                              :max="maxDate"
                              color="red darken-4"
                              class="rounded-lg"
                              locale="id"
                            ></v-date-picker>
                          </v-dialog>
                        </v-col>

                      <!-- TanggalAkhir -->
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-dialog
                          v-model="MenuTglAkhir"
                          :return-value.sync="date"
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.TanggalAkhir"
                              label="Tgl Akhir"
                              prepend-icon="mdi-calendar"
                              color="dark"
                              readonly
                              dense
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            :min="minDate"
                            :max="maxDate"
                            v-model="editedItem.TanggalAkhir"
                            @input="MenuTglAkhir = false"
                            year-icon="mdi-calendar-blank"
                            color="red darken-4"
                            class="rounded-lg"
                            locale="id"
                          ></v-date-picker>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <span class="d-inline-block text-truncate">
                  Status : <span>{{Status}}</span>
                </span>

                <v-spacer></v-spacer>

                <v-btn
                  dark
                  depressed
                  color="red darken-4 mx-n2"
                  class="text-capitalize"
                  @click="Simpan"
                >
                  <v-icon left>mdi-content-save</v-icon>
                  <span class="mr-1">Simpan</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <div v-show="loading == true">
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              bottom
              height="6"
              color="red darken-4"
            ></v-progress-linear>
          </div>
        </v-toolbar>

        <div>
          <ejs-grid
            ref='gridkontrak'
            style="height: 100%;"
            height="43vh"
            gridLines='Both'
            id="kontrakview"
            :dataSource="DataKontrak"
            :allowPaging="true"
            :allowSorting='true'
            :allowResizing='true'
            :allowGrouping='true'
            :toolbar='toolbarOptions'
            :commandClick="commandClick"
            :pageSettings='pageSettings'
            :allowExcelExport='true'
            :allowPdfExport='true'
          >
            <e-columns>
              <e-column headerText="Aksi" textAlign='Center' width="150" :commands="commands" :lockColumn='true' :customAttributes="customAttributes">
                <div class="btn-group">
                  <v-btn type="button" class="btn btn-default" (click)="prediemRowEdit($event)">
                    <i class="fa fa-pencil"></i>
                  </v-btn>
                  <v-btn type="button" class="btn btn-default" (click)="prediemRowDelete($event)">
                    <i class="fa fa-trash"></i>
                  </v-btn>
                </div>
              </e-column>
              <e-column field='KodeKaryawan' headerText='Kode Karyawan' textAlign='Left' :visible="false" width=150></e-column>
              <e-column field='karyawan.Nama' headerText='Nama Karyawan' textAlign='Left' width=300></e-column>
              <e-column field='NoKontrak' headerText='No. Kontrak' textAlign='Left' width=150></e-column>
              <e-column field='TanggalMulai' headerText='Tgl Mulai' width=150></e-column>
              <e-column field='TanggalAkhir' headerText='Tgl Akhir' width=150></e-column>
              <e-column field='DiBuatOleh' headerText='DibuatOleh' textAlign='Left' width=150></e-column>
              <e-column field='DiBuatTgl' headerText='DibuatTgl' textAlign='Left' width=150></e-column>
              <e-column field='DiUbahOleh' headerText='DiubahOleh' textAlign='Left' width=150></e-column>
              <e-column field='DiUbahTgl' headerText='DiubahTgl' textAlign='Left' width=150></e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </v-card>
    </v-container>
  
    <!-- dialog lihat kontrak -->
    <v-dialog
      v-model="DialogLihatKontrak"
      max-width="700px"
      scrollable
    >
      <v-card
        outlined
        class="rounded-lg"
        :color="$vuetify.theme.dark ? '#272727' : 'grey lighten-4'"
      >
        <v-toolbar flat height="64">
          <v-toolbar-title class="title">
            <span class="text-h5 mx-1">Detail Kontrak Karyawan</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="DialogLihatKontrak = false"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <div class="ml-n7 mb-n5">
            <v-timeline dense>
              <v-timeline-item
                v-for="(kontrak,index) in LihatKontrakData"
                :key="index"
              >
                <template v-slot:icon>
                  <v-avatar size="31px" color="success" v-show="new Date(kontrak.TanggalAkhir) > new Date(today)">
                    <span class="text-h6 white--text" v-text="kontrak.NoKontrak"></span>
                  </v-avatar>
                  <v-avatar size="31px" color="error" v-show="new Date(kontrak.TanggalAkhir) <= new Date(today)">
                    <span class="text-h6 white--text" v-text="kontrak.NoKontrak"></span>
                  </v-avatar>
                </template>

                <v-sheet width="100px" class="elevation-3 rounded-t-lg mx-4 overline font-weight-bold text-center" v-show="new Date(kontrak.TanggalAkhir) > new Date(today)">
                  <span>Sekarang</span>
                </v-sheet>
                
                <v-card>
                  <div class="pa-3">
                    <v-row no-gutters>
                      <v-col cols="12" sm="6" md="6">
                        <div class="mb-n1"><span class="subtitle-1 font-weight-bold">Nama :</span></div>
                        <div><span class="subtitle-1" v-text="LihatKontrakData.Nama"></span></div>
                      </v-col>
                      <v-col cols="12" sm="3" md="3">
                        <div class="mb-n1"><span class="subtitle-1 font-weight-bold">Mulai :</span></div>
                        <div><span class="subtitle-1" v-text="kontrak.TanggalMulai"></span></div>
                      </v-col>
                      <v-col cols="12" sm="3" md="3">
                        <div class="mb-n1"><span class="subtitle-1 font-weight-bold">Berakhir :</span></div>
                        <div><span class="subtitle-1" v-text="kontrak.TanggalAkhir"></span></div>
                      </v-col>
                    </v-row>
                  </div>

                  <v-row no-gutters class="mt-n3">
                    <v-col cols="12">
                      <v-alert text dense type="success" class="ma-2" v-show="new Date(kontrak.TanggalAkhir) > new Date(today)">
                        <span>Kontrak Berjalan</span>
                      </v-alert>
                    </v-col>

                    <v-col cols="12">
                      <v-alert text dense type="error" class="ma-2" v-show="new Date(kontrak.TanggalAkhir) <= new Date(today)">
                        <span>Kontrak Telah Habis</span>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { GridPlugin, Page, Sort, Group, Resize, Toolbar, Search, CommandColumn, ExcelExport, PdfExport } from "@syncfusion/ej2-vue-grids";
import api from "@/services/http";
Vue.use(GridPlugin);

export default {
  data() {
    return {
      date:new Date().toISOString().substr(0, 10),
      MenuTglMulai: false,
      MenuTglAkhir: false,
      mobile:null,
      Permission:[],
      ValidCreate:false,
      valid:null,
      today:new Date().toJSON().slice(0,10).split('-').join('-'),
      AlertKontrak:null,
      KodeKaryawan:null,
      NoKontrak:null,
      textAktif:true,
      umenu:[],
      loading:true,
      Kode:null,
      minDate: new Date('2005-01-01').toISOString().slice(0,10),
      maxDate: new Date('2100-01-01').toISOString().slice(0,10),
      DialogPilihExport:false,
      windowSize: {x: 0, y: 0},
      MenuExport: false,
      commands: [],
      customAttributes : {class: 'customcss'},
      Alert:false,
      DialogLihatKontrak:false,
      akun:[],
      usermenu:null,
      AlertDataTerhapus:false,
      AlertBerhasilTerubah:false,
      user:[],
      LihatKontrakData:[],
      token:null,
      DialogTambahKontrak: false,
      DialogPilihKaryawan: false,
      form: new FormData,
      editedIndex: -1,

      defaultItem: {
        id:"",
        KodeKaryawan:"",
        Nama:"",
        NoKontrak: "",
        TanggalMulai: "",
        TanggalAkhir: "",
      },
      editedItem: {
        id:"",
        KodeKaryawan:"",
        Nama:"",
        NoKontrak: "",
        TanggalMulai: "",
        TanggalAkhir: "",
      },
      toolbarOptions: ['Search'],
      DataKontrak: [],
      DataKontrakAll:[],
      pageSettings: {pageSize: 10, pageSizes :['5','10','15','20','50','All']},
    };
  },
  

  provide: {
    grid: [Page, Sort, Group, Resize, Toolbar, CommandColumn, Search,ExcelExport,PdfExport ]
  },

 async mounted(){
    this.token = localStorage.getItem('token')
    this.user = JSON.parse(localStorage.getItem('user'))
    Vue.prototype.$BukaNotifKontrak = this.BukaNotifKontrak
    // await Promise.all([this.saya()])
    if (this.Permission.some(a => a == 'R')) {
      this.valid = true
      this.getdata()
      this.getdataKaryawan()
      console.log('rx',this.$route.params.notifikasi)
      this.commands.push({buttonOption: { cssClass: "e-flat Lihat", iconCss: "e-icons mata", content:' '} })
      this.editedItem.TanggalMulai = this.date
      this.editedItem.TanggalAkhir = this.date
    }else{
      this.loading = false
      this.valid = false
    }
    if (this.Permission.some(a => a == 'C')) {
     this.ValidCreate = true
    }
    if (this.Permission.some(a => a == 'U')) {
     this.commands.push({buttonOption: { cssClass: "e-flat Edit", iconCss: "e-edit e-icons", content:' '} })
    }
    if (this.Permission.some(a => a == 'D')) {
     this.commands.push({ buttonOption: { cssClass:"e-flat Delete", iconCss: "e-delete e-icons", content:' '} })
    }
    
  },

  created(){
    //get action for this page
    console.log('param',this.$route.params.Permission)
    let permisi = this.$route.params.Permission
    this.Permission = permisi == null ? [] : permisi.split(',')
    },

  computed: {
    formTitleKontrak() {
      return this.editedIndex === -1 ? 'Tambah Kontrak Baru' : 'Ubah Data Kontrak'
    },
    formTitlePilihKaryawan() {
      return this.editedIndex === -1 ? 'Pilih Karyawan' : 'Ubah Data Kontrak'
    },
    StatusFormPilihKaryawan() {
      return this.editedIndex === -1 ? 'Pilih ' : 'Ubah Data Kontrak'
    },
    Status () {
      return this.editedIndex === -1 ? 'Baru' : 'Ubah'
    },
  },
  
  watch: {
      // let a = this.DataKontrak.filter( function(item){return (item.UserMenu == newval.toUpperCase());} )
      // this.umenu = a
    windowSize(){
      if (this.windowSize.x < 480) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },

    DialogTambahKontrak (val) {
      val || this.KeluarDialogKontrak()
    },

    DialogPilihKaryawan (val) {
      val || this.KeluarDialogPilihKaryawan()
    },

    Alert(){
      this.AlertItem()
    },
    AlertDataTerhapus(){
      this.AlertItem()
    },
    AlertBerhasilTerubah(){
      this.AlertItem()
    },
  },

  methods: {
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    cariKode(Kode){
      if (Kode != null) {
          this.textAktif = false
        }else{
          this.textAktif = true
        }
      if (Kode == null || Kode == '' || Kode.length == 0) {
        this.editedIndex = -1
          this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      }else{
      this.Kode = Kode.toUpperCase()
      let a = this.DataKontrak.filter( function(item){return (item.Kode == Kode.toUpperCase());} );
      if (a.length > 0) {
          this.editedIndex = 1;
          this.textAktif = false
          this.editedItem.Nama = a[0].Nama
          // this.editedItem.UserMenu = a[0].UserMenu
        }else{
          //do nothing
        }
      }
    },
  async saya(){
       if (this.$menu == undefined) {
        //  await api.get('/menu',{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
        await api.get('/menu?token='+this.token).then(
        res=>{
          if (res) {
            this.akun = res.data
            let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
            //do noting
          }
        }).catch(err=>{
          // alert(err)
          if (err == "Error: Request failed with status code 401" && this.$route.path != "/login") {
            this.logout()
          }
        })
       }else{
        this.akun = this.$menu
        let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
       }     
    },
      // cekmenu(){
        
      // },

    PilihNamaKaryawan(){
      this.DialogPilihKaryawan = false
    },
    BukaNotifKontrak(data){
      this.DialogTambahKontrak = true
      this.editedItem.Nama = data.karyawan.Nama
      this.editedItem.KodeKaryawan = data.karyawan.KodeKaryawan
      // NOtifcok
      let kontrak = this.DataKontrakAll.find(x => x.KodeKaryawan === this.editedItem.KodeKaryawan)
      // console.log('find',kontrak)
      this.editedItem.NoKontrak = parseFloat(kontrak[0].NoKontrak)+1
      this.editedItem.TanggalMulai = ''
      this.editedItem.TanggalAkhir = ''
      this.minDate = new Date(String(kontrak[0].TanggalAkhir)).toISOString().slice(0,10)
    },

    Simpan(){
      if(this.formTitleKontrak === "Tambah Kontrak Baru"){
        this.Toast('Data Disimpan')
        // console.log(this.editedItem)
          api.post('/kontrakkaryawan?token='+this.token,{
            KodeKaryawan: this.editedItem.KodeKaryawan,
            NoKontrak: this.editedItem.NoKontrak,
            TanggalMulai: this.editedItem.TanggalMulai,
            TanggalAkhir: this.editedItem.TanggalAkhir,
            //Notifikasi
            Notifikasi:this.$route.params.notifikasi != undefined ? this.$route.params.notifikasi.id : null,
            Kategori:"Approval",
            Judul:"Perpanjang Kontrak",
            Konten:"Kontrak Telah Di Perpanjang",
            Attachment:null,
            Dari:this.$user.KodeKaryawan,
            Tujuan:this.editedItem.KodeKaryawan
            //end of notif
            },
            { headers: { 'Content-Type': 'multipart/form-data',"Authorization" : `Bearer ${this.token}` } }
            )
            .then((res)=>{
              if (res) {
                // this.Alert = true
                if(this.$route.params.notifikasi != undefined){
                  delete this.$route.params.notifikasi
                }
                this.$CekNotifKontrak()
                this.getdata()
                this.getdataKaryawan()
              } else {
                //
              }
            })
      }else if(this.formTitleKontrak === "Ubah Data Kontrak"){
        this.Toast('Data Diubah')
        //  api.put("/kontrakkaryawan/"+this.KodeKaryawan+'/'+this.NoKontrak+'/?token='+this.token,{
          api.put("kontrakkaryawan/"+this.editedItem.id+'/?token='+this.token,{
            NoKontrak: this.editedItem.NoKontrak,
            TanggalMulai: this.editedItem.TanggalMulai,
            TanggalAkhir: this.editedItem.TanggalAkhir,
					},
          { headers: {"Authorization" : `Bearer ${this.token}` } })
					.then((res) => {
						if (!res) {
							//do nothing
						}else{
              this.getdata()
              this.getdataKaryawan()
              // this.AlertBerhasilTerubah = true
						}
						
					})
      }
      this.KeluarDialogKontrak()    
    },

    commandClick: function(args) {
      if (args.target.classList.contains("custombutton")){
        // let data = JSON.stringify(args.rowData);
		
        // console.log(args.rowData);
        
      }else if (args.target.classList.contains("Lihat")) {
        this.DialogLihatKontrak = true
        // console.log(args)
        let a = this.DataKontrakAll.filter( function(item){return (item.KodeKaryawan == args.rowData.KodeKaryawan);} );
        this.LihatKontrakData = a[0]
        // console.log(a)
      }else if (args.target.classList.contains("Delete")){
        console.log(args.rowData.id)
        var r = confirm("Yakin Hapus Data?");
        if (r == true) { 
          this.Toast('Data Dihapus')
          api
            // .delete("/kontrakkaryawan/"+args.rowData.KodeKaryawan+'/'+args.rowData.NoKontrak+'?token='+this.token)
            .delete("/kontrakkaryawan/"+args.rowData.id+'?token='+this.token)
            .then((res) =>{
              // console.log(res);
              if (res) {
                this.getdata()
              this.AlertDataTerhapus = true
              }
              
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (args.target.classList.contains("Edit")){
        this.KodeKaryawan = args.rowData.KodeKaryawan
        this.NoKontrak = args.rowData.NoKontrak
        let data = args
            this.editedIndex = 1;
            // console.log(data)
            this.editedItem.id = data.rowData.id
            this.editedItem.Nama = data.rowData.karyawan.Nama
            this.editedItem.NoKontrak = data.rowData.NoKontrak
            this.editedItem.TanggalMulai = data.rowData.TanggalMulai
            this.editedItem.TanggalAkhir = data.rowData.TanggalAkhir
            this.DialogTambahKontrak = true

          // if (args.rowData.Aktif == 0) {
          //   this.dataTemp = args.rowData
          //   this.dialogAktifData = true
          //   }else{
          //       let data = args;
          //       this.editedIndex = 1;
          //       this.Kode = data.rowData.Kode
          //       this.editedItem.Nama = data.rowData.Nama
          //       this.editedItem.Password = data.rowData.Password
          //       this.editedItem.UserMenu = data.rowData.UserMenu
          //       // this.dialogWO = true;
          //   }
      }
    },

    PdfExport(){
      let data = document.getElementById('kontrakview').ej2_instances[0];
      // console.log(data)
      data.pdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName:"ReportKontrak.pdf"
      }
      data.columns[1].visible = false
      data.columns[4].visible = false
      data.columns[3].visible = false
      // let pdfExportProperties = {
       
      // };
      // this.$refs.gridkontrak.columns[0].visible = false;
      // this.$refs.gridkontrak.columns[1].visible = true;
      this.$refs.gridkontrak.pdfExport(data.pdfExportProperties);
    },
    excelExport(){
      this.$refs.gridkontrak.excelExport();
      // console.log(this.$refs.gridkontrak)
      // let data = document.getElementById('kontrakview').ej2_instances[0];
      // console.log(data)
      // data.excelExport();
    },

    KeluarDialogKontrak () {
      this.DialogTambahKontrak = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.Kode = null
        this.textAktif = true
      })
    },

    KeluarDialogPilihKaryawan () {
      this.DialogPilihKaryawan = false
    },

    rowSelectedPilihKaryawan:function(args){
      this.editedItem.KodeKaryawan = args.data.KodeKaryawan
      this.editedItem.Nama = args.data.Nama
      if (args.data.kontrak.length != 0) {
        this.editedItem.NoKontrak = parseInt(args.data.kontrak.NoKontrak)+1
      }else{
        this.editedItem.NoKontrak = 1
      }
    },

    rowDblClickPilihKaryawan: function(args){
      this.editedItem.KodeKaryawan = args.rowData.KodeKaryawan
      console.log(args)
      this.editedItem.Nama = args.rowData.Nama
      if (args.rowData.kontrak.length > 0) {
        this.editedItem.NoKontrak = parseInt(args.rowData.kontrak[args.rowData.kontrak.length-1].NoKontrak)+1
      }else{
        this.editedItem.NoKontrak = 1
      }
      this.DialogPilihKaryawan = false
    },

    // beforeExport: function(args) {
      
    // },

    getdata(){
      if (this.valid == true) {
        // api.get('/karyawan',{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
      //   api.get('/karyawan?token='+this.token,).then(
			// 	res => {
			// 		// console.log(res);
      //     let kontrak = []
      //     let kontrakall = []
      //     // let karyawan = []
      //     let a = res.data.filter( function(item){return (item.kontrak.length != 0);} );
      //     console.log('t',a)
      //     for (let index = 0; index < a.length; index++) {
      //       let filterkontrak = a[index].kontrak.filter(w => w.StatusData != 0)
      //       // console.log('x',filterkontrak)
      //       // let s = a[index].kontrak[a[index].kontrak.length-1]
      //       let s = filterkontrak[filterkontrak.length-1]
      //       let sa = filterkontrak.reverse()
      //       sa.KodeKaryawan = a[index].KodeKaryawan
      //       sa.Nama = a[index].Nama
      //       s.Nama = a[index].Nama
      //       kontrak.push(s)
      //       kontrakall.push(sa)
      //     }
      //     this.DataKontrak = kontrak
      //     console.log('k',this.DataKontrak)
      //     this.DataKontrakAll = kontrakall
      //     this.loading = false
			// 	},
			// 	err => {
			// 		console.log(err);
			// 	}
			// )
        api.get('/kontrakkaryawan?token='+this.token).then(
          res=>{
            let data = [], kontrak = [], kontrakall = []
            let es = res.data
            var flags = [], l = res.data.length, i;
            for( i=0; i<l; i++) {
                if( flags[res.data[i].KodeKaryawan]) continue;
                flags[res.data[i].KodeKaryawan] = true;
                data = es.filter(w=> w.KodeKaryawan == res.data[i].KodeKaryawan)
                data.KodeKaryawan = res.data[i].KodeKaryawan
                data.Nama = res.data[i].karyawan.Nama
                let s = data[data.length-1]
                let sa = data.reverse()
                kontrak.push(s)
                kontrakall.push(sa)
            }
            this.DataKontrak = kontrak
            this.DataKontrakAll = kontrakall
            this.loading = false
            // let a = res.data.filter( function(item){return (item.NoKontrak );} );
            // console.log('a',a)
              // for (let index = 0; index < res.data.length; index++) {
              //   let filterkontrak = a[index].kontrak.filter(w => w.StatusData != 0)
              //   // console.log('x',filterkontrak)
              //   // let s = a[index].kontrak[a[index].kontrak.length-1]
              //   let s = filterkontrak[filterkontrak.length-1]
              //   let sa = filterkontrak.reverse()
              //   sa.KodeKaryawan = a[index].KodeKaryawan
              //   sa.Nama = a[index].Nama
              //   s.Nama = a[index].Nama
              //   kontrak.push(s)
              //   kontrakall.push(sa)
              // }
          }
        )
      }
      
    },

    getdataKaryawan(){
      if (this.valid == true) {
        // api.get('/karyawan',{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
        api.get('/selectkaryawan?token='+this.token,).then(
				res => {
					// console.log('awoks',res.data);
          // let fixkaryawan = []
          // for (let index = 0; index < res.data.length; index++) {
          //    const s = res.data[index]
          //   if (res.data[index].kontrak.length != 0) {
          //    s.kontrak = res.data[index].kontrak[res.data[index].kontrak.length-1]
          //   }else{
          //     s.kontrak = []
          //   }
          //   fixkaryawan.push(s)
          // }
          // console.log(fixkaryawan)
          // let date = new Date()
          // let karyawan = res.data.filter( function(item){return (date.setHours(0,0,0,0) > new Date(item.kontrak.TanggalAkhir).setHours(0,0,0,0) || item.kontrak.length == 0);});
          // console.log('karya',karyawan)
          this.DataKaryawan = res.data
          this.loading = false
          if(this.$route.params.notifikasi != undefined){
            this.DialogTambahKontrak = true
            this.editedItem.Nama = this.$route.params.notifikasi.karyawan.Nama
            this.editedItem.KodeKaryawan = this.$route.params.notifikasi.karyawan.KodeKaryawan
            // NOtifcok
            let kontrak = this.DataKontrakAll.find(x => x.KodeKaryawan === this.editedItem.KodeKaryawan)
            // console.log('find',kontrak)
            this.editedItem.NoKontrak = parseFloat(kontrak[0].NoKontrak)+1
            this.editedItem.TanggalMulai = ''
            this.editedItem.TanggalAkhir = ''
            this.minDate = new Date(String(kontrak[0].TanggalAkhir)).toISOString().slice(0,10)
            // console.log('soxk',a)
          }
				},
				err => {
					console.log(err);
				}
			)
      }
      
    },

    Toast(data){
      this.$swal.fire({
      toast: true,
      icon: 'success',
      title: data,
      animation: false,
      position: 'top-right',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true})
    },

    Reset() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    logout(){
        api.post('/logout', this.token)
        .then(res=>{
          // console.log(res)
          if (res) {
            //
          }
        })
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        location.reload(false)
      },

    AlertItem(){
      window.setInterval(() => {
        this.Alert = false;
        this.AlertDataTerhapus = false;
        this.AlertBerhasilTerubah = false;
      }, 3000)
    },
  },
}
</script>

<style>
.mata:before{ 
    content:'\e345'; 
} 
.custom-file-input {
  border: 1px solid #ccc;
  display: inline-block solid #ccc;
  padding: 6px 6px 6px;
  cursor: pointer;
}

.e-grid .e-rowcell.customcss{
  background-color: #d6d6d6;
}
.e-grid .e-headercell.customcss{
  background-color: #ecedee;
}
</style>


{% endraw %}